import React from "react";

import JHookLogo from "../../images/J-Hooks.png";

import PortfolioPage from "../../components/portfolioPage";

const links = [
	{
		endpoint: "https://www.npmjs.com/package/j-hooks",
		title: "npm",
	},
	{
		endpoint: "https://github.com/FellowshipOfThePing/J-Hooks",
		title: "Github",
	},
];

const JHooks = () => (
	<PortfolioPage
		image={JHookLogo}
		name="J-Hooks"
		description="Small library of custom React hooks, published to npm. Installing an npm package is less tedious than copying/pasting code between projects, and I had never published an npm package before, so I saw a 2 🐦 1 🗿 situation."
		links={links}
	/>
);

export default JHooks;
